body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}
h1 {
  margin: 0;
}
a, a:visited {
  text-decoration: none;
  color: #D00000;
}
a:hover {
  text-decoration: underline;
}