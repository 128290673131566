.tools {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.tools > li {
    flex: 0 0 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
    margin: .5em 0;
    padding: .5em 0;
}
.tools > li div:first-of-type {
    text-align: center;
}
.tools > li > div > i {
    font-size: 2em;
}
.tools > li > div:last-of-type {
    text-align: center;
    font-size: 1.1em;
}

.tools > li.black {
    background-color: #333333;
    color: #DFA933;
}
.tools > li.gold {
    background-color: #DFA933;
    color: #333333;
}

@media only screen and (min-width: 601px) {
    #Tools-p {
        font-size: 1.5em;
        padding-bottom: 1em;
    }

    .tools > li {
        flex: 0 0 125px;
    }
}

@media only screen and (min-width: 1200px) {
    .tools {
        max-width: 800px;
        margin: 0 auto;
    }
}