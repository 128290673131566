nav {
    background-color: #333333;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    width: 100%;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 8px;
    z-index: 9999;
}
a.nav-link {
    flex: 1 1 0;
    text-align: center;
    color: #DFA933;
    font-size: 1.1em;
    background-color: #333333;
    border: 2px solid #DFA933;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
}
#middle-nav-left {
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
}
#middle-nav-right {
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
}
a.nav-link:hover {
    background-color: #DFA933;
    color: #333333;
}

.active {
    box-shadow: inset 0 0 10px #DFA933;
}
.active:hover {
    box-shadow: inset 0 0 10px #333333;
}

#up-arrow {
    flex: 0 0 42px;
    align-self: center;
    height: 40px;
    padding: 0;
    border: none;
    background-color: #333333;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}
#up-button {
    font-size: 0;
    max-height: 40px;
    max-width: 42px;
    padding: 0;
    margin: 0;
    border: none;
    color: #DFA933;
    background-color: #333333;
    transition: none;
    transition: color .3s;
}
#up-button:hover {
    color: #333333;
    cursor: pointer;
}
#up-button:hover > i {
    background-color: #DFA933;
    border-radius: 50%;
}

@media only screen and (min-width: 1200px) {
    nav {
        width: auto;
        padding: 0 12em;
    }
}

@media only screen and (min-width: 1680px) {
    nav {
        padding: 0 16em;
    }
}

@media only screen and (min-width: 2560px) {
    nav {
        padding: 0 26em;
    }
}
