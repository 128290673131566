header {
    background-color: #333333;
    padding: 8px 16px 16px 16px;
    text-align: center;
    color: #DFA933;
}
header > img {
    border-radius: 50%;
    width: 15em;
    margin: 1em;
}
header > p {
    margin: 0;
    margin-top: 8px;
}

#links > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
#links > ul > li {
    display: inline;
    padding: 0 5px;
    margin: 4px 3px;
    font-size: 32px;
}
header a, header a:visited {
    color: #DFA933;
}

.social-link, .social-link > i {
    transition: .3s;
}
.social-link:hover {
    color: #333333;
}
.social-link:hover > i {
    background-color: #DFA933;
}