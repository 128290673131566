.project {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
    margin: 1.5em 0;
}
.project-content {
    padding: .25em 1em 1em 1em;
}
.project-image {
    padding: 1em 0;
    width: 100%;
    text-align: center;
    display: block;
    background-color: #333333;
}
.project-image > img {
    max-width: 95%;
    transition: .3s;
}
.project-image:hover > img {
    opacity: .25;
}

.project-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}
.project-links a {
    align-items: center;
    font-size: 1.75em;
    color: #333333;
    margin: 0 .5em;
    transition: .3s;
}
.project-links a > i {
    background-color: #DFA933;
    padding: .5em;
    border-radius: 50%;
    transition: .3s;
}
.project-links a:hover {
    color: #DFA933;
}
.project-links a:hover > i {
    background-color: #333333;
}
@media only screen and (min-width: 650px) {
    #portfolio-projects {
        display: flex;
        flex-wrap: wrap;
        align-content: space-around;
        justify-content: center;
    }
    .project {
        margin: 1em;
        flex: 1 0 150px;
    }
}

@media only screen and (min-width: 1680px) {
    #portfolio > * {
        max-width: 1200px;
    }

    .project-content > h3 {
        font-size: 1.5rem;
    }
    .project-content > p {
        font-size: 1.25rem;
    }
}