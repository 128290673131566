.section > * {
    margin: 0 .75em;
}
.section > h2 {
    margin: .75em 0;
}
.section:last-of-type {
    padding-bottom: 1em;
}

h2::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-bottom: 4px solid #333333;
}
h2 {
    color: #D00000;
    position: relative;
    margin-bottom: 1em;
    font-size: 1.75em;
    font-weight: 400;
    text-align: left;
    background: inherit;
    padding-left: .25em;
}
h2 > span {
    position: relative;
    display: inline-block;
    padding: 0 .25em;
    background: #fff;
}
h2 > span > i {
    color: #333333;
}
h2[class=right] {
    text-align: right;
    padding-left: 0;
    padding-right: .25em;
}

#Hello-p {
    font-size: 1.25em;
}
p[class=right] {
    text-align: right;
}

#work-history {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.container {
    border-bottom: 2px solid #D00000;
}
.container:last-of-type {
    border-bottom: none;
}



@media only screen and (min-width: 601px) {
    h2 {
        padding-left: 2em;
    }
    h2[class=right] {
        padding-right: 2em;
    }
    #Hello-p {
        font-size: 1.5em;
    }
}

@media only screen and (min-width: 900px) {
    .section {
        padding-bottom: 5em;
    }
    .section:last-of-type {
        padding-bottom: 5em;
    }
    .section > h2 {
        margin-bottom: 5rem;
        font-size: 2em;
        max-width: 100%;
        padding: 0 7em;
    }

    .section > * {
        max-width: 900px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1680px) {
    .section > h2 {
        padding: 0 14em;
        font-size: 2.25em;
    }
    
    #Hello-p {
        font-size: 2em;
    }
}

@media only screen and (min-width: 2560px) {
    .section > h2 {
        padding: 0 18em;
    }
}